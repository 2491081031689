import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import logo from "./general-lee.png";
import Typography from "@mui/material/Typography";
import OkIcon from "@mui/icons-material/ArrowBack";
import packageJson from '../package.json';
import { Link } from "@mui/material";

type onCloseHandler = () => void;

interface AboutBoxProps {
    visible: boolean,
    onClose: onCloseHandler
}

export default function AboutBox(props: AboutBoxProps) {

    return (
        <Dialog maxWidth="md" open={props.visible} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle className="about-title" id="form-dialog-title">
                Info over deze website
            </DialogTitle>
            <DialogContent>
                <img src={logo} width={576} alt="Nitrox logo"/>
                <Typography variant="h4">
                    KVINDEK: De lijst van 50+
                </Typography>
                <Typography variant="h4">
                    Name: {packageJson.name}
                </Typography>
                <Typography variant="h4">
                    Version: {packageJson.version}
                </Typography>
                <Typography variant="h4">
                    Gemaakt door <Link href="https://www.twisq.nl">TWISQ</Link>
                </Typography>
            </DialogContent>
            <DialogActions>
            <Button variant="contained" color="primary" className="button" onClick={props.onClose}>
                Sluiten
                <OkIcon className="right-icon"/>
            </Button>
            </DialogActions>
        </Dialog>
    );
}