import React, { useState } from 'react';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AboutBox from './AboutBox';
import MainScreen from './MainScreen';

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App() {

  const [mainMenu, setMainMenu] = useState<null | HTMLElement>(null);
  const [showAbout, setShowAbout] = useState<boolean>(false);
  const [darkMode, setDarkMode] = React.useState<boolean>(true);
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setDarkMode((prevMode) => (!prevMode));
      },
    }),
    [],
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? "dark" : "light",
        },
      }),
    [darkMode],
  );

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMainMenu(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMainMenu(null);
  }

  const handleOpenAbout = () => {
    setMainMenu(null);
    setShowAbout(true);
  }

  const handleCloseAbout = () => {
    setShowAbout(false);
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <div className="App">
            <header>

              <AppBar position="static">
                <Toolbar>
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                    onClick={handleOpenMenu}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={mainMenu}
                    keepMounted
                    open={Boolean(mainMenu)}
                    onClose={handleCloseMenu}
                  >
                    <MenuItem onClick={handleOpenAbout}>Info...</MenuItem>
                  </Menu>
                  <AboutBox visible={showAbout} onClose={handleCloseAbout}/>

                  <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    DE LIJST VAN 50+
                  </Typography>
                  <IconButton sx={{ ml: 1 }} onClick={colorMode.toggleColorMode} color="inherit">
                    {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                  </IconButton>
                </Toolbar>
              </AppBar>
            </header>

            <section>
              <MainScreen/>
            </section>
          </div>
        </CssBaseline>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;