import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { useEffect, useState } from "react";
import SongTable from "./SongTable";
import Song from "./Song";
import Paper from "@mui/material/Paper";
import songs_json from "./songs.json";
import packageJson from '../package.json';
import { Button, ButtonGroup, Typography } from '@mui/material';

type SongJson = {
    year: number;
    title: string;
    artist: string;
    photo_copyright: string;
    news_photo: string;
    story: string[];
    spotify: string;
    youtube: string;
}

type SongListJson = {
    songs: SongJson[];
}

export default function MainScreen() {

    const [songs, setSongs] = useState<Song[]>([]);
    const [currentSong, setCurrentSong] = useState<Song | null>(null);
    const [minYear, setMinYear] = useState<number>(9999);
    const [maxYear, setMaxYear] = useState<number>(0);

    useEffect(() => {
        var minYear = 9999;
        var maxYear = 0;
        setSongs((songs_json as SongListJson).songs.map((cfg) => {
            var song = new Song();
            song.year = cfg.year;
            song.title = cfg.title;
            song.artist = cfg.artist;
            song.photo_copyright = cfg.photo_copyright;
            song.news_photo = cfg.news_photo;
            song.spotify = cfg.spotify;
            song.youtube = cfg.youtube;
            song.story = cfg.story;
            if (cfg.year > maxYear) maxYear = cfg.year;
            if (cfg.year < minYear) minYear = cfg.year;
            return song;
        }))
        setMinYear(minYear);
        setMaxYear(maxYear);
    }, []);

    const header = (currentSong === null) ?
        "Alle nummers in één playlist"
    :
        currentSong.year + " - " + currentSong.title + " - " + currentSong.artist;

    const spotify = (currentSong === null) ?
        (
            <iframe 
                src={"https://open.spotify.com/embed/playlist/4X8EZExlkNwFvdKBrbm9SA?si=a9f39ed28b864f14&pt=221a90eac7cc681b9e1ef05d1a523666"}
                title="Spotify"
                width="100%" 
                height="256"
                frameBorder={0}
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                loading="lazy"> 
            </iframe>
        )
        :
        (
            <iframe
                src={"https://www.youtube.com/embed/" + currentSong.youtube}
                title="YouTube"
                frameBorder={0}
                height={400}
                width={600}
            ></iframe>
        );

    const story = (currentSong === null) ?
            <Typography variant="body1" align="left" marginBottom={2}>
                Op mijn vijftigste verjaardag ben ik begonnen met iedere week het plaatsen van een verhaaltje op Facebook. Ik ben de jaren doorgegaan van 1972 tot nu en van ieder jaar heb ik een nummer gekozen en daar bij geschreven waarom het een bijzonder nummer is voor mij. Ik schreef ook kort iets over een nieuwsfeitje uit dat jaar. Op deze webpagina kan je alles nog eens teruglezen. Veel plezier!
            </Typography>
    :
        <>
            {currentSong.story.map((s) => (
                <Typography variant="body1" align="left" marginBottom={2}>{s}</Typography>
            ))}
        </>;
    
    const buttons = (currentSong === null) ?
        <Button
            variant="contained"
            onClick={() => setCurrentSong(songs[0])}>
            Start bij het begin
        </Button>
    :
        <ButtonGroup>
            <Button
                variant="contained"
                disabled={currentSong.year <= minYear}
                onClick={() => {
                    const newsong = songs.find((s) => (s.year === (currentSong.year - 1) ));
                    setCurrentSong(newsong === undefined ? null : newsong);
                }}
            >
                Ga naar vorig jaar
            </Button>
            <Button
                variant="contained" 
                onClick={() => setCurrentSong(null)}>
                Ga naar playlist
            </Button>
            <Button
                variant="contained"
                disabled={currentSong.year >= maxYear}
                onClick={() => {
                    const newsong = songs.find((s) => (s.year === (currentSong.year + 1) ));
                    setCurrentSong(newsong === undefined ? null : newsong);
                }}
            >
                Ga naar volgende jaar
            </Button>
        </ButtonGroup>
        

    const homepage = packageJson.homepage;
    const imgurl = homepage + "/img/" + currentSong?.news_photo;
    const copyright = currentSong?.photo_copyright !== undefined ?
        (<><br/><Typography variant="body2">Foto: {currentSong.photo_copyright}</Typography></>)
    :
        (<><br/>&nbsp;</>)

    const image = (currentSong === null) ?
        <></>
    :
        <>
            <img src={imgurl} alt={"Newspicture of year " + currentSong.year}/>
            {copyright}
        </>;
    
    return (
        <>
        <Grid container spacing={1}>

        <Grid xs={4}>
            <Paper
                sx={{
                    padding: 1,
                    margin: 1,
                    overflowX: "auto"
                }}
                elevation={2}
            >
                <SongTable 
                    songs={songs}
                    onSelectSong={(song:Song) => setCurrentSong(song)}
                />
            </Paper>
        </Grid>
        <Grid xs={8}>
            <Paper
                sx={{
                    padding: 1,
                    margin: 1,
                    overflowX: "auto"
                }}
                elevation={2}
            >
                <Grid xs={12}>
                    <Typography variant="h3">{header}</Typography>
                </Grid>
                <Grid xs={12}>
                    {spotify}
                </Grid>
                <Grid xs={12} marginBottom={2}>
                    {buttons}
                </Grid>
                <Grid xs={12}>
                    {story}
                </Grid>
                <Grid xs={12}>
                    {image}
                </Grid>
            </Paper>
        </Grid>
        </Grid>
    </>
  
   );
}

/*<iframe 
src={currentSong.spotify}
title="Spotify"
width="100%" 
height="256"
frameBorder={0}
allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
loading="lazy"> 
</iframe>
*/