export default class Song {
    year: number;
    title: string;
    artist: string;
    news_photo: string;
    photo_copyright: string;
    story: string[];
    spotify: string;
    youtube: string;

    constructor()
    {
        this.year = 0;
        this.title = "";
        this.artist = "";
        this.photo_copyright = "";
        this.news_photo = "";
        this.story = [];
        this.spotify = "";
        this.youtube = "";
    }
}