import { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import type { MRT_ColumnDef } from "material-react-table";
import Song from "./Song";

interface SongTableProps {
  songs: Song[],
  onSelectSong: (song:Song) => void;
}

export default function SongTableTable(props: SongTableProps) {

  //column definitions
  const columns = useMemo<MRT_ColumnDef<Song>[]>(
    () => [
      {
        accessorKey: "year",
        header: "Jaar",
        size: 40
      },
      {
        accessorKey: "title",
        header: "Titel",
      },
      {
        accessorKey: "artist",
        header: "Artiest",
      }
    ],
    [],
  );

  return (
    <>
      <MaterialReactTable
        columns={columns}
        data={props.songs}
        enableGlobalFilter={true}
        enablePagination={false}
        initialState={{ density: 'compact' }}
        muiTableBodyRowProps={({ row }) => ({
          onClick: (event) => {
            props.onSelectSong(row.original);
          },
          sx: {
            cursor: 'pointer', //you might want to change the cursor too when adding an onClick
          },
        })}
      />
    </>
  );
}